import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useContext } from 'react';
import {
  TranscriptMsg,
  CaptionShard,
  CaptionsSource,
  SimpleShard,
  CaptionShardWord,
} from 'shared/hooks/axon/gateway.types';
import { zeroWidthJoiner } from 'shared/utils/separator-joiners.util';
import { RootState } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import { setCaptionFromSavvy } from 'state/captions/captionsSlice';

const checkIfIsFuser = (source: CaptionsSource) => source === 'FUSER';

const formatShardText = (shardText: string) => {
  return shardText
    .replaceAll('<c>', '')
    .replaceAll('</c>', '')
    .replaceAll('&apos;', "'")
}
const formatShardWord = (wordText: string) => {
  if(wordText.length){
    if(wordText.charAt(0) !== ' '){
      wordText = ' ' + wordText;
    }
    if (wordText.charAt(0) !== zeroWidthJoiner.space) {
      wordText = zeroWidthJoiner.space + wordText
    }
  }
  return wordText;
}

export const useUpdateTranscript = () => {
  const { detailedShards } = useContext(LandingPageContext);
  const dispatch = useDispatch()
  const captions = useSelector((state: RootState) => state.captions.value);


  const updateTranscript = ({ shards, source }: TranscriptMsg) => {
    if (!checkIfIsFuser(source)) {
      return;
    }
    let processingShardId;
    try {
      if (!detailedShards.current) {
        Object.entries(shards as unknown as SimpleShard).forEach(([id, shardText]) => {
          const shard: CaptionShard = {
            shardId: Number(id),
            shardText,
            alternatives: [],
            editable: true,
            shardWords: [],
            formattedText: formatShardText(shardText),
            userEditing: false,
          };
          dispatch(setCaptionFromSavvy(shard))
        });
        return;
      }
      Object.values(shards).forEach(({ shardId, shardText, shardWords }) => {
        processingShardId = shardId;
        if (shardWords.length === 1 && shardText.includes('<c>')) {
          shardWords[0].shardId = shardId;
          shardWords[0].wordText = formatShardWord(shardWords[0].wordText)
          shardWords[0].isCorrection = true;
          shardWords[0].isFirst = true;
          shardWords[0].isLast = true;
          shardWords[0].isBeyondEditableLimit = false;
        } else {
          let textWords = shardText.split(' ').filter((word: string) => (word.length));
          shardWords.forEach((shardWord: CaptionShardWord, index: number, thisArray: CaptionShardWord[]) => {
            shardWord.shardId = shardId;
            let foundIndex = textWords.findIndex((textWord: string) => (textWord.includes(shardWord.wordText)))
            if (foundIndex !== -1) {
              const textWord = textWords[foundIndex];
              if (textWord.includes(`<c>${shardWord.wordText}</c>`)
                || textWord === `${shardWord.wordText}</c>`) {
                shardWord.isCorrection = true;
              } else {
                shardWord.isCorrection = false;
              }
              shardWord.isBeyondEditableLimit = false;
              textWords.splice(foundIndex, 1)
            }
            shardWord.isLast = index === thisArray.length - 1;
            shardWord.wordText = formatShardWord(shardWord.wordText)
          })
        }

        const captionShard: CaptionShard = {
          shardId: shardId,
          shardText: shardText,
          alternatives: shardWords['0'] ? shardWords[0].wordAlternatives : [],
          editable: true,
          shardWords,
          formattedText: formatShardText(shardText),
          userEditing: false,
        };
        dispatch(setCaptionFromSavvy(captionShard))

      });

    } catch (error: any) {
      console.error('updateTranscript failed with error ', error.message, '/nstack: ', error.stack)
      console.error(`updateTranscript failed processing shard ${processingShardId} with shards `, JSON.stringify(shards))
    }
  };

  return {
    updateTranscript,
  };
};