import { useEffect, useState } from 'react';
import { ShardWord } from 'shared/components/ui/editor/editor/ShardWord';
import { PShard } from 'shared/components/ui/editor/editor/CorrectionsPopUp.styled';
import { CaptionShard, CaptionShardWord } from 'shared/hooks/axon/gateway.types';

interface ShardParagraphProps extends CaptionShard {}

export const ShardParagraph = ({
  shardText,
  shardId,
  alternatives,
  editable,
  shardWords,
  formattedText,
}: ShardParagraphProps) => {
  const [text, setText] = useState<string>(shardText);
  const [isOnCorrection, setIsOnCorrection] = useState(false);

  useEffect(() => {
    setText(shardText);
  }, [shardText]);

  return (
    <PShard
      isOnCorrection={isOnCorrection}
      key={`shard-${shardId}`}
      data-alternatives={JSON.stringify(alternatives)}
      className="item"
      data-shardid={shardId.toString()}
      onCopy={(e) => {
        e.preventDefault();
        navigator.clipboard.writeText('');
      }}
      onCut={(e) => {
        e.preventDefault();
        navigator.clipboard.writeText('');
      }}
      onPaste={(e) => false}
      onDragStart={(e) => false}
      data-text={formattedText}
      data-type="shard"
    >
      {shardWords.map((shardWord: CaptionShardWord, index) => (
        <ShardWord
          key={`shard-word-${shardId}-${shardWord.wordId}-${index}`}
          {...shardWord}
        />
      ))}
    </PShard>
  );
};

