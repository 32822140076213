export type AlignedWord = {
  beginning?: number;
  confidence?: number;
  ending?: number;
  word: string;
};

export type Alternative = {
  alternatives: [AlignedWord, ...AlignedWord[]];
  correctionsId: number;
  fusionTimestamp: number;
};

export type AsrPhrase = {
  beginning: number;
  confidence: number;
  ending: number;
  normalizedText: string;
  text: string;
  words: [Alternative, ...Alternative[]];
};

export type CaptionShard = {
  shardId: number;
  shardText: string;
  alternatives: string[];
  editable: boolean;
  shardWords: CaptionShardWord[];
  formattedText: string;
  userEditing: boolean;
};

export type SimpleShard = {
  [key: string]: string;
};

export type CaptionShardWord = {
  shardId: number;
  wordAlternatives: string[];
  wordId: number;
  wordText: string;
  isCorrection?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isBeyondEditableLimit?: boolean;
};

export type CaptionShardWordCorrection = {
  shardId?: number;
  wordId: number;
  wordText: string;
};

export type ShardType = {
  [index: string]: {
    shardId: number;
    shardText: string;
    shardWords: CaptionShardWord[];
  };
};

export enum CaptionsSource {
  CUBIC = 'CUBIC',
  MICROSOFT = 'MICROSOFT',
  FUSER = 'FUSER',
}

export interface TranscriptMsg {
  type: 'transcriptData';
  source: CaptionsSource;
  resultType: 'PARTIAL' | 'FINAL';
  streamId: 'revoiced' | 'original';
  alternatives: [AsrPhrase, ...AsrPhrase[]];
  shards: ShardType;
}

export const audioSampleRate = 44100;
export const incomingAudioSampleRate = 8000;

type PartialMessage = {
  Id: string;
  Text: string;
  Offset?: number;
  Duration: number;
};

export type Word = {
  Word: string;
  Offset: number;
  Duration: number;
};

export type NBest = {
  Confidence: number;
  Lexical: string;
  ITN: string;
  MaskedITN: string;
  Display: string;
  Words: Word[];
};

export type ProcessedMessage = {
  Id: string;
  RecognitionStatus?: number;
  Offset?: number;
  Duration: number;
  DisplayText: string;
  NBest?: NBest[];
};
export type Message = PartialMessage &
  ProcessedMessage & {
    partial: boolean;
    asr?: 'CUBIC' | 'MICROSOFT' | 'FUSER';
    stream?: 'revoiced' | 'original';
  };

export enum GateWaySockets {
  PFCSocket = 'PFCSocket',
  captionerSocket = 'captionerSocket',
}