import {
  current,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import {
  CaptionShard,
} from 'shared/hooks/axon/gateway.types';

const WORD_LIMIT = 240;
interface Captions {
  value: CaptionShard[],
  buffer: CaptionShard[],
  bufferingCaptions: boolean,
}

const initialState: Captions = {
  value: [],
  buffer: [],
  bufferingCaptions: false,
}

type CaptionUserEditingPayload = {
  shardId: number;
  userEditing: boolean;
}

type CaptionShardWordTextPayload = {
  shardId: number,
  wordIndex: number,
  wordText: string,
}

const captionsSlice = createSlice({
  name: 'captions',
  initialState: initialState,
  reducers: {
    setCaptionFromSavvy: (state, action: PayloadAction<CaptionShard>) => {

      let captions = JSON.parse(JSON.stringify(current(state).value));;
      captions[action.payload.shardId] = action.payload;
      let wordCount = 0;
      for(let index = captions.length; index >= 0; --index){
        let shard = captions[index];
        wordCount += shard?.shardWords?.length || 0;
        if(wordCount >= WORD_LIMIT){
          // set isBeyondEditableLimit
          if(!shard?.shardWords?.[0]?.isBeyondEditableLimit){
            for(let shardWord of shard?.shardWords){
              shardWord.isBeyondEditableLimit = true;
              shardWord.wordAlternatives = [];
            }
          } else {
            break;
          }
        }
      }
      state.value = captions;
    },
    setCaption: (state, action: PayloadAction<CaptionShard>) => {
      state.value[action.payload.shardId] = action.payload;
    },
    clearCaptions: (state,) => {
      state.value = [];
    },
    setCaptionUserEditing: (state, action: PayloadAction<CaptionUserEditingPayload>) => {
      if(current(state).value?.[action.payload.shardId]){
        state.value[action.payload.shardId].userEditing = action.payload.userEditing;
      }
    },
    setCaptionShardWordText: (state, action: PayloadAction<CaptionShardWordTextPayload>) => {
      if(current(state).value?.[action.payload.shardId]?.shardWords?.[action.payload.wordIndex]){
        state.value[action.payload.shardId].shardWords[action.payload.wordIndex].wordText = action.payload.wordText;
      }
    },
    setBufferingCaptions: (state, action: PayloadAction<boolean>) => {
      if(action.payload === false){
        let buffer = current(state).buffer;
        // copy buffer to state
        while(buffer.length > 0){

          const bufferedCaption = JSON.parse(JSON.stringify(buffer[buffer.length - 1]));
          if(bufferedCaption){
            state.value[bufferedCaption.shardId] = bufferedCaption;
            state.buffer = state.buffer.toSpliced(-1, 1);
            buffer = current(state).buffer;
          }
        }
      }
      state.bufferingCaptions = action.payload;
    }
  }
})

export const {
  setCaption,
  setCaptionFromSavvy,
  clearCaptions,
  setCaptionUserEditing,
  setCaptionShardWordText,
  setBufferingCaptions
} = captionsSlice.actions;

export default captionsSlice.reducer;