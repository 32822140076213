import { useState } from 'react'
import { Popover, Menu } from 'antd';
import { CaptionShardWord } from 'shared/hooks/axon/gateway.types';
import { useEditorEvents } from './useEditorEvents'
import * as Styled from './CorrectionsPopUp.styled';

const LINE_BREAK = '<br/>';
const NORMALIZED_LINE_BREAK = '\n';
const NO_CORRECTIONS_AVAILABLE = 'No corrections available';

export const ShardWord = ({
  shardId,
  wordAlternatives,
  wordId,
  wordText,
  isCorrection,
  isFirst,
  isLast,
  isBeyondEditableLimit,
} : CaptionShardWord) => {

  const {
    handleCorrectionMenuClick,
    handleShardWordContextMenu,
  } = useEditorEvents();

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if(!isBeyondEditableLimit){
      setOpen(newOpen);
    }
  };

  const displayText = wordText.replaceAll(LINE_BREAK, NORMALIZED_LINE_BREAK);
  const hasWordAlternatives = !!wordAlternatives!.length
  let className = isCorrection ? 'correction' : '';
  if(isBeyondEditableLimit){
    className += ' beyond-editable-limit';
  }

  let menuItems = [];
  if(wordAlternatives!.length === 0){
    menuItems = [{
      key: `${wordId}-${NO_CORRECTIONS_AVAILABLE}`,
      label: NO_CORRECTIONS_AVAILABLE,
    }]
  } else {
    menuItems = Array.from(new Set(wordAlternatives))
    .filter((item: string, index: number) => (index < 3))
    .map((wordAlternative:string) => {
      return {
        key:`${wordId}-${wordAlternative}`,
        label: wordAlternative,
      }
    });
  }

  const handleMenuClick = (clickedItem: any) => {
    if(hasWordAlternatives){
      const newText = clickedItem?.domEvent?.target?.innerText;    
      handleCorrectionMenuClick(shardId as number, wordId as number, wordText as string, newText);
    }
    hide();
    clickedItem?.domEvent!.preventDefault();
    clickedItem?.domEvent!.stopPropagation();
  }
  return (
    <Popover
      trigger="contextMenu"
      overlayInnerStyle={{
        borderRadius: 12,
        minWidth: 180,
      }}
      content={
        <Styled.CorrectionsMenu hasWordAlternatives={hasWordAlternatives} >
          <Menu items={menuItems} onClick={handleMenuClick} />
        </Styled.CorrectionsMenu>
      }
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottom"
      arrow={false}    
    >
      <span
        data-wordid={wordId.toString()}
        data-shardid={shardId.toString()}
        data-type="shard-word"
        className={className}
        onContextMenu={handleShardWordContextMenu}
      >
        {renderLineBreakText(displayText)}
      </span>
    </Popover>
  )  
}

function renderLineBreakText(text: string){
  const linebreakIndex = text.indexOf(NORMALIZED_LINE_BREAK)
  if(linebreakIndex !== -1){
    const displayText = text.split(NORMALIZED_LINE_BREAK);
    const lastTextPart = displayText.length-1;
    return displayText.map((textPart:string, index) => {
      if(index < lastTextPart){
        return (
          <>
            {textPart}
            <br />
          </>
        )
      } else {
        return textPart
      }
    })
  } else {
    return text
  }
}