import { AuthenticationContext } from 'modules/authentication/authentication.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useContext, useRef, useState } from 'react';
import { config } from 'shared/config/cts.config';
import { useCorrections } from 'shared/hooks/axon/corrections/use-corrections.hook';
import {
  useDispositions,
  DispositionNames,
} from 'shared/hooks/axon/dispositions/use-dispositions.hook';
import { incomingAudioSampleRate } from 'shared/hooks/axon/gateway.types';
import { handleAudio } from 'shared/hooks/axon/helpers/handle-audio.helper';
import {
  GatewayEvent,
  type ConnectingToCallEvent,
  type HangUpEvent,
} from 'shared/hooks/axon/ipcts-gw/gateway-events.enum';
import { ReasonCodes } from 'shared/hooks/axon/ipcts-gw/termination-and-non-bill-reason-codes';
import { useUpdateTranscript } from 'shared/hooks/axon/ipcts-gw/use-update-transcript.hook';
import { SoundBuffer } from 'shared/hooks/axon/ipcts-pfc-gw/SoundBuffer';
import { useSocketFactory } from 'shared/utils/socket-factory/use-socket-factory.hook';
import { RootState } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import { clearCaptions } from 'state/captions/captionsSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import logger from 'services/logger';

const useGateWay = () => {
  const notAcceptingCalls = useRef(false);
  const twoOnCall = useRef(false);
  const audioStarted = useRef(false);
  const audioEnded = useRef(false);
  const receviedShardCount = useRef(0);
  const receviedNonSilentCaption = useRef(false);
  const localCallId = useRef('');
  const { getUserToken } = useContext(AuthenticationContext);
  const user = useSelector((state: RootState) => state.user.value);
  const audio = useSelector((state: RootState) => state.audio);
  const dispatch = useDispatch();
  const { agentTimeBetweenCalls } = useFlags();
  function getAudio() {
    return audio;
  }
  const { callId, setCallId, setShowLobby, setIsCallActive, detailedShards } =
    useContext(LandingPageContext);
  const { updateTranscript } = useUpdateTranscript();
  const callAudioCtx = useRef<AudioContext>(new AudioContext());
  const soundBuffer = new SoundBuffer(
    callAudioCtx.current,
    incomingAudioSampleRate,
    2
  );

  const useGateWaySocket = useSocketFactory({
    wsUrl: `${config.REACT_APP_GW_SOCKET}?agent_id=${user?.employeeID}`,
  });

  const { send, openSocket, closeSocket, setEventsHandlers, isOpen } =
    useGateWaySocket();

  const { sendCorrection, sendDeletion, sendInsertion, sendSubstitution } =
    useCorrections(send);

  const { sendDisposition } = useDispositions(send);

  const closeConnections = async () => {
    audioStarted.current = false;
    audioEnded.current = false;
    dispatch(clearCaptions());
    closeSocket();
  };
  const sendReadyForCalls = () => {
    logger.info(
      {
        methodName: 'sendReadyForCalls',
        message: '** GatewayEvent sendReadyForCalls',
      },
      false
    );
    send(
      JSON.stringify({
        event: GatewayEvent.readyForCalls,
      })
    );
  };
  const sendCallDisposition = (disposition: DispositionNames) => {
    sendDisposition(localCallId.current, disposition);
  };
  const sendSilentDisposition = (): DispositionNames => {
    // SilentNoTimer: (two_on_call and two_on_call_rcvd) not received and agent selected silent
    // SilentTimerRunning: (two_on_call and two_on_call_rcvd) received and agent selected silent
    // SilentMidCall: agent selected silent and audio not received.
    let disposition: DispositionNames;

    if (twoOnCall.current) {
      if (
        audioStarted.current &&
        !audioEnded.current &&
        receviedNonSilentCaption.current
      ) {
        disposition = DispositionNames.SilentMidCall;
      } else {
        disposition = DispositionNames.SilentTimerRunning;
      }
    } else {
      disposition = DispositionNames.SilentNoTimer;
    }
    sendDisposition(localCallId.current, disposition);
    return disposition;
  };
  const timedReadyForCalls = () => {
    const waitTime = agentTimeBetweenCalls * 1000;
    setTimeout(() => {
      if (notAcceptingCalls.current) {
        return;
      }
      sendReadyForCalls();
    }, waitTime);
  };
  const sendNotAcceptingCalls = (status: string, reason: string) => {
    notAcceptingCalls.current = true;
    send(
      JSON.stringify({
        event: GatewayEvent.notAcceptingCalls,
        payload: {
          status,
          reason,
        },
      })
    );
  };
  const containsNonSilentCaption = (msg: any) => {
    return Object.values(msg.payload.shards).find(
      (shard: any) =>
        !!shard?.shardText
          ?.replaceAll('·', '')
          .replaceAll('(Silence.)', '')
          .trim()
    );
  };
  const handleCaptions = (msg: any) => {
    logger.info(
      {
        methodName: '** GatewayEvent.captions',
        callId: localCallId.current,
        message: 'payload: ' + JSON.stringify(msg.payload),
      },
      false
    );
    if (!receviedNonSilentCaption.current && containsNonSilentCaption(msg)) {
      receviedNonSilentCaption.current = true;
    }
    receviedShardCount.current++;
    updateTranscript(msg.payload);
  };
  const handleHello = () => {
    logger.info(
      {
        methodName: '** GatewayEvent.hello',
      },
      false
    );

    send(
      JSON.stringify({
        event: GatewayEvent.authenticate,
        payload: {
          agent_name: user.given_name + ' ' + user.sn,
          call_center: user.callCenterId,
          email: user.agentEmail,
          language: user.language,
          role: user.role.name,
          token: getUserToken(),
          session_login_id: user.sessionLoginId,
          session_type: user.axonSessionType,
        },
      })
    );
  };
  const handleReady = (msg: any) => {
    logger.info(
      {
        methodName: '** GatewayEvent.ready',
      },
      false
    );
    notAcceptingCalls.current = false;
    sendReadyForCalls();
    setShowLobby(false);
    setIsCallActive(false);
  };
  const handleStartAudio = () => {
    logger.info(
      {
        methodName: '** GatewayEvent.startAudio',
      },
      false
    );
  };
  const handleConnectionError = (msg: any) => {
    logger.error(
      {
        methodName: '** GatewayEvent.connectionError',
        callId: localCallId.current,
        message: msg,
      },
      false
    );
  };
  const handleError = (msg: any) => {
    logger.error(
      {
        methodName: '** GatewayEvent.error',
        callId: localCallId.current,
        message: msg,
      },
      false
    );
  };
  const handleCaptionsEnded = () => {
    logger.info(
      {
        methodName: '** GatewayEvent.captionsEnded',
        callId: localCallId.current,
      },
      false
    );
    closeSocket();
  };
  const handleConnectionClosed = () => {
    logger.info(
      {
        methodName: '** GatewayEvent.connectionClosed',
        callId: localCallId.current,
      },
      false
    );
    receviedNonSilentCaption.current = false;
    receviedShardCount.current = 0;
    audioStarted.current = false;
    audioEnded.current = false;
    dispatch(clearCaptions());
    setShowLobby(true);
    setIsCallActive(false);
  };
  const handleStatus = (msg: any) => {
    logger.info(
      {
        methodName: '** GatewayEvent.status',
        message: msg,
      },
      false
    );
  };
  const handleAlternativesUnavailable = () => {
    logger.warn(
      {
        methodName: '** GatewayEvent.alternativesUnavailable',
      },
      false
    );
    detailedShards.current = false;
  };
  const handleReadyForCalls = (msg: string) => {
    logger.info(
      {
        methodName: '** GatewayEvent.readyForCalls',
        message: msg,
      },
      false
    );
  };
  const connectingToCall = (msg: ConnectingToCallEvent) => {
    logger.setPersistentCallId(msg.payload?.call_id);
    logger.info(
      {
        methodName: '** GatewayEvent.connectingToCall',
        callId: msg.payload?.call_id,
        message: JSON.stringify(msg),
      },
      false
    );
    receviedNonSilentCaption.current = false;
    receviedShardCount.current = 0;
    twoOnCall.current = !!msg.payload?.two_on_call_rcvd;
    localCallId.current = msg.payload?.call_id;
    setCallId(localCallId.current);
    setShowLobby(false);
    setIsCallActive(true);
    sendCallDisposition(DispositionNames.Normal);
  };

  const handleHangup = async (msg: HangUpEvent) => {
    logger.info(
      {
        methodName: '** GatewayEvent.hangup',
        callId: localCallId.current,
        message: JSON.stringify(msg),
      },
      false
    );
    receviedNonSilentCaption.current = false;
    receviedShardCount.current = 0;
    audioStarted.current = false;
    audioEnded.current = false;
    setCallId('');
    // 5 second timer goes here
    setShowLobby(false);
    setIsCallActive(false);
    dispatch(clearCaptions());
    logger.info(
      {
        methodName: '** GatewayEvent.hangup',
        callId: localCallId.current,
        message: `isOpen ${isOpen()}`,
      },
      false
    );
    timedReadyForCalls();
    logger.setPersistentCallId('');
  };

  const handleAudioMessage = (msg: any) => {
    if (!audioStarted.current) {
      logger.info(
        {
          methodName: '** GatewayEvent.handleAudioMessage',
          message: 'audio started ' + JSON.stringify(msg),
        },
        false
      );
      audioStarted.current = true;
    }
    if (!getAudio().muted) {
      handleAudio(msg.payload, soundBuffer);
    }
  };
  const handleAudioEnded = (msg: any) => {
    audioEnded.current = true;
    logger.info(
      {
        methodName: '** GatewayEvent.audioEnded',
        message: JSON.stringify(msg),
      },
      false
    );
  };
  const handleTwoOnCall = (msg: any) => {
    twoOnCall.current = true;
    logger.info(
      {
        methodName: '** GatewayEvent.twoOnCall',
        message: JSON.stringify(msg),
      },
      false
    );
  };

  const sendTerminateCall = (reason: ReasonCodes) => {
    logger.info(
      {
        methodName: '** GatewayEvent.terminateCall',
        callId: localCallId.current,
        message: '** GatewayEvent terminateCall',
      },
      false
    );
    send(
      JSON.stringify({
        event: GatewayEvent.terminateCall,
        payload: { reason },
      })
    );
  };

  const eventHandlers: Record<string, (value?: any) => any> = {
    [GatewayEvent.captions]: handleCaptions,
    [GatewayEvent.hello]: handleHello,
    [GatewayEvent.ready]: handleReady,
    [GatewayEvent.startAudio]: handleStartAudio,
    [GatewayEvent.connectionError]: handleConnectionError,
    [GatewayEvent.error]: handleError,
    [GatewayEvent.captionsEnded]: handleCaptionsEnded,
    [GatewayEvent.connectionClosed]: handleConnectionClosed,
    [GatewayEvent.status]: handleStatus,
    [GatewayEvent.alternativesUnavailable]: handleAlternativesUnavailable,
    [GatewayEvent.readyForCalls]: handleReadyForCalls,
    [GatewayEvent.connectingToCall]: connectingToCall,
    [GatewayEvent.hangup]: handleHangup,
    [GatewayEvent.audio]: handleAudioMessage,
    [GatewayEvent.audioEnded]: handleAudioEnded,
    [GatewayEvent.twoOnCall]: handleTwoOnCall,
  };

  const openConnection = async () => {
    if (isOpen()) {
      notAcceptingCalls.current = false;
      sendReadyForCalls();
      setShowLobby(false);
      setIsCallActive(false);
    } else {
      setEventsHandlers(eventHandlers);
      openSocket();
    }
  };

  return {
    receviedShardCount,
    openConnection,
    closeConnections,
    sendCorrection,
    sendCallDisposition,
    sendSilentDisposition,
    sendDeletion,
    sendInsertion,
    sendSubstitution,
    sendNotAcceptingCalls,
    sendTerminateCall,
  };
};

export default useGateWay;
